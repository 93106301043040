import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash';

export const MAP_TYPES = {
  'CHOROPLETH': 'CHOROPLETH',
  'MARKERS': 'MARKERS',
  'CLUSTERS': 'CLUSTERS',
};

export const initialState = {
  ready: false,
  zoom: 0,
  mapBounds: {
    west: 0,
    south: 0,
    east: 0,
    north: 0,
  },
  latitude: 0,
  longitude: 0,
  mapType: '', // see map MAP_TYPES above
  dynamic: '',
};

export const formatMapBounds = ({ west, south, east, north }) => {
  return {
    west: Boolean(west) ? parseFloat(west) : initialState.mapBounds.west,
    south: Boolean(south) ? parseFloat(south) : initialState.mapBounds.south,
    east: Boolean(east) ? parseFloat(east) : initialState.mapBounds.east,
    north: Boolean(north) ? parseFloat(north) : initialState.mapBounds.north,
  };
};

const mapSlice = createSlice({
  name: 'mapConfig',
  initialState,
  reducers: {
    ready(state) {
      state.ready = true;
    },

    updateMapState(state, action) {
      const { zoom, mapBounds, latitude, longitude, mapType } = action.payload;

      state.zoom = Boolean(zoom) ? parseInt(zoom) : state.zoom;
      state.mapBounds = !isEmpty(mapBounds) ? formatMapBounds(mapBounds) : state.mapBounds;
      state.latitude = Boolean(latitude) ? parseFloat(latitude) : state.latitude;
      state.longitude = Boolean(longitude) ? parseFloat(longitude) : state.longitude;
      state.mapType = Boolean(mapType) ? MAP_TYPES[mapType] : state.mapType;
    }
  },
});

export const { ready, updateMapState } = mapSlice.actions;
export default mapSlice.reducer;
