import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => 
  <a className={`logo ${className}`} href="/" role="heading" aria-roledescription="Contract Research Map">
    <img src="/images/main-logo.png" />
  </a>;

Logo.propTypes = {
  className: PropTypes.string
};

export default Logo;