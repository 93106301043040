import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { pinLocationItem, unpinLocationItem, activateLocationItem, deactivateLocationItem } from '../../reducers/locationsReducer';
import ListItems from './ListItems';
import NoResults from '../map-tools/facetsAccordion/NoResults';
import ListNameFilter from './ListNameFilter';
import Spinner from '../common/Spinner';
import { LocationItemShape } from '../../shapes/shapes';

function LocationsContent({ locationItems, activeLocationItem, pinnedLocationItem, isLoadingData }) {
  const dispatch = useDispatch();  
  const [filteredItems, setFilteredItems] = useState(locationItems);
  const [filterTextContent, setFilterTextContent] = useState('');

  useEffect(() => {
    setFilteredItems(locationItems);
  },[locationItems]);

  function onItemClickHandler({ id: itemId, url, type }) {
    if (type === 'polygon') {
      window.location.href = url;
      return;
    }

    if (itemId === pinnedLocationItem) {
      dispatch(unpinLocationItem());
      return;
    }
    dispatch(pinLocationItem(itemId));
  };

  function onMouseEnterHandler(markerId) {
    if (Boolean(pinnedLocationItem)) {
      return;
    }
    dispatch(activateLocationItem(markerId));
  };

  function onMouseLeaveHandler() {
    if (Boolean(pinnedLocationItem)) {
      return;
    }
    dispatch(deactivateLocationItem());
  };

  const onResetFilters = () => {
    setFilteredItems(locationItems);
    setFilterTextContent('');
  }

  const onResetMap = () => {
    window.location.href = "/";
  }

  const filterLocationsHandler = ({ newText, matchingItems }) => {
    setFilteredItems(matchingItems);
    setFilterTextContent(newText);
  };

  const displayIsLoading = isLoadingData;
  const displayNoResults = isLoadingData === false && isEmpty(filteredItems);
  const displayLocationsList = !displayNoResults && !displayIsLoading;

  return (
    <div id="locations">
      <ListNameFilter
        textChangeFilter={filterLocationsHandler}
        listItems={locationItems}
        textValue={filterTextContent}
        isLoadingData={isLoadingData}
      />
      {
        displayIsLoading ?
          <div className="loading-spinner">
            <Spinner />
          </div> : <></>
      }
      { displayNoResults ?
          <NoResults onResetFilters={onResetFilters} onResetMap={onResetMap}/> : <></>
      }
      { displayLocationsList ?
          <ListItems
            items={filteredItems}
            onItemClick={onItemClickHandler}
            pinnedItem={pinnedLocationItem}
            onMouseEnterItem={onMouseEnterHandler}
            onMouseLeaveItem={onMouseLeaveHandler}
            activeItem={activeLocationItem}
          /> : <></>
      }
    </div>
  );
}

LocationsContent.propTypes = {
  locationItems: PropTypes.arrayOf(PropTypes.shape(LocationItemShape)),
  activeLocationItem: PropTypes.string,
  pinnedLocationItem: PropTypes.string,
  isLoadingData: PropTypes.bool.isRequired,  
};

export default LocationsContent;

