import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

const Sidepanel = ({ id, onOpen, onClose, title, children, className, direction, type, isOpen }) => {
  const [visible, setVisible] = useState(isOpen);
  const slideDirection = direction || 'rtl';  // direction: direction || 'rtl', // possible directions 'rtl', 'ltr'

  const togglePanel = (ev) => {
    const visibleState = !visible;
    setVisible(visibleState);

    if (visibleState === true) {
      onOpen && onOpen(ev);
    } else {
      onClose && onClose(ev);
    }
  };

  const modifierOptions = {
    flexHeight: 'flex-height'
  }

  useEffect(() => setVisible(isOpen), [isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 3000);
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div className={`sidepanel__wrapper sidepanel__wrapper--${slideDirection}
      ${type && modifierOptions[type] ? `sidepanel__wrapper--${modifierOptions[type]}` : ''} 
      ${className ? className : ''} ${visible ? 'visible' : 'hidden'}`}
    >
      <section id={id} className="sidepanel">
        <button className="sidepanel__button btn" onClick={togglePanel}>
          <i className={`bi bi-caret-${visible ? 'left' : 'right'}-fill`}></i>
        </button>
        { title ? 
          <h4 className="sidepanel__title">{ title }</h4> : <></>
        }

        <div className="sidepanel__content">
          { children }
        </div>
        <div className="sidepanel__footer"></div>
      </section>
    </div>
  );
};

Sidepanel.propTypes = {
  id: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default Sidepanel;