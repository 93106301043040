import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { isEmpty } from 'lodash';

import { adsSelector } from '../../reducers/appReducer';
import KevelService from '../../services/kevelService';
import { adShape } from '../../shapes/shapes';

function AdItem ({ data }) {
  const {
    adId: id,
    clickUrl,
    impressionUrl,
    title,
    description,
    imageUrl
  } = data;

  return (
    <div className="ad-item" id={id}>
      <i className="ad-item__corner">
        <img className="flask-icon" src="/images/flask.svg" />
      </i>
      <a className="ad-item__content ad-content" href={clickUrl}>
        <div className="ad-content__image-wrapper image-wrapper">
          <img className="image-wrapper__image" src={imageUrl} />
        </div>
        <div className="ad-content__info ad-info">
          <h3 className="ad-info__title">{title}</h3>
          <p className="ad-info__description">{description}</p>
        </div>
      </a>
      <img src={impressionUrl} display="none" />
    </div>
  );
}

AdItem.propTypes = {
  data: PropTypes.shape(adShape).isRequired
};

const Ads = ({ getAdCount }) => {
  const [adsData, setAdsData] = useState();
  const { adsConfig, kevelData } = useSelector(adsSelector);

  useEffect(() => {
    const ads = KevelService.parseAdData(kevelData.decisions[adsConfig.divName]) || [];
    setAdsData(ads);
    getAdCount(ads.length);
  },[]);

  return isEmpty(adsData) ?
    (<></>) : (
    <div className="ad-list">
      { adsData.map((adItemData, idx) => 
          (<AdItem data={adItemData} key={idx}/>))
      }
    </div>
  );
}

Ads.propTypes = {
  getAdCount: PropTypes.func.isRequired,
};

export default Ads;