import { combineReducers } from 'redux';
import { createReducer, createSelector } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash';

import locationsReducer from './locationsReducer';
import mapConfigReducer from './mapConfigReducer';
import facetsReducer from './facetsReducer';

export const initialState = {
  mapboxAccessToken: '',
  adsEnabled: false,
  adsConfig: { // ToDo: remove this as is not necessary anymore in the FE
    divName: '',
    adTypes: [],
    zoneIds:[],
    count: 0,
  },
  kevelClientConfig: {
    networkId: 0,
    siteId: 0,
  },
  kevelData: {},
  pageData: {
    type: 'LOCATION',
    value: ''
  },
};

const appConfig = createReducer(initialState, () => {}); // has no reducer actions as it is preloaded from rails server;

export const adsSelector = createSelector(
  (state) => state,
  (state) => ({
    enabled: state.appConfig.adsEnabled,
    adsConfig: state.appConfig.adsConfig,
    kevelClientConfig: state.appConfig.kevelClientConfig, // clean kevel ads data from here, it's done now in the BE
    kevelData: state.appConfig.kevelData,
  })
);

const appReducer = combineReducers({
  appConfig,
  locations: locationsReducer,
  mapConfig: mapConfigReducer,
  facets: facetsReducer,
});

export default appReducer;
