import React, { useState } from 'react';
import PropTypes from 'prop-types';

const InfoMenu = ({ onClick, className }) => {
  const tabIds = ['about', 'learn-more'];

  const openModal = (e) => {
    const selectedTabIdx = parseInt(e.target.dataset.tab, 10);
    onClick(selectedTabIdx);
  };

  return (
    <div className={`dropdown info-menu ${className}`}>
      <a href="#" className="dropdown-toggle btn btn-outline-primary " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        Info <span className="caret"></span>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        <a className="dropdown-item navbar-text" onClick={openModal}
          data-tab={0} data-target="#site-info-modal" data-toggle="modal" href="#">
          About
        </a>
        <a className="dropdown-item navbar-text" onClick={openModal} 
          data-tab={1} data-target="#site-info-modal" data-toggle="modal" href="#">
          Learn More
        </a>
      </div>
    </div>
  );
};

InfoMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default InfoMenu;