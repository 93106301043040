import { connect } from 'react-redux';

import MainPage from '../components/MainPage';
import { pinLocationItem, unpinLocationItem, deactivateLocationItem } from '../reducers/locationsReducer';
import { updateMapState } from '../reducers/mapConfigReducer';
import { fetchLocations } from '../actions/locationActions';

const mapStateToProps = (state) => {
  const { appConfig, facets, locations, mapConfig } = state;
  const { mapboxAccessToken, pageData, adsEnabled, adsConfig } = appConfig;
  const { pinnedLocationItem, activeLocationItem, features: locationItems, isLoadingData, dataType } = locations;
  const { selectedFacet, items: facetItems, } = facets;

  return {
    adsEnabled,
    adsMaxCount: adsConfig.count,
    mapConfig,
    mapboxAccessToken,
    pageData,
    pinnedLocationItem,
    activeLocationItem,
    locationItems,
    locationsDataType: dataType,
    isLoadingData,
    selectedFacet,
    facets: facetItems
  };
}

const mapDispatchToProps = (dispatch) => ({
  // Map/List functions
  pinLocationAction(locationId) {
    dispatch(pinLocationItem(locationId))
  },

  unpinLocationAction(){
    dispatch(unpinLocationItem())
  },

  deactivateLocationItem() {
    dispatch(deactivateLocationItem());
  },

  updateMapState(zoom, mapBounds, mapType) {
    dispatch(updateMapState({ zoom, mapBounds, mapType }));
  },

  fetchLocations({ pageData, zoom, mapBounds, selectedFacet }) {
    dispatch(fetchLocations({ pageData, zoom, mapBounds, selectedFacet }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);