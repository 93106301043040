import fetch from '../utils/fetch';

async function Search (searchText) {
  const searchUrl = '/searches.json';

  return fetch(`${searchUrl}?q=${searchText}`)
    .then((results) => {
      return results.map((result) => {
        const { name, url, id } = result;
        return { name, url, id };
      });
    });
};

export default Search;