import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash';

import LocationsService from '../services/locationsService';

export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async ({ pageData, zoom, mapBounds, selectedFacet }) => {
    const { response } = await LocationsService.getNewData(pageData, zoom, mapBounds, selectedFacet);
    const { facets, features } = response;
    return {
      facets: !isEmpty(facets) && !isEmpty(facets.items) ? facets.items : [],
      features,
    };
  }
);