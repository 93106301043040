import React from 'react';
import PropTypes from 'prop-types';

import GenericModal from '../common/GenericModal';

const SiteInfoModal = ({ modalId, actualTab }) => (
  <GenericModal id={modalId} title="Site Information">
    <ModalContent actualTab={actualTab}/>
  </GenericModal>
);

const ModalContent = ({ actualTab }) =>
  (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a data-tabidx={0} className={`nav-link ${ actualTab === 0 ? 'active' : '' } font-weight-bold`}
            href="#about" aria-controls="about" role="tab" data-toggle="tab">
              About</a>
        </li>
        <li className="nav-item">
          <a data-tabidx={0}className={`nav-link ${ actualTab === 1 ? 'active' : '' } font-weight-bold`}
            href="#learn-more" aria-controls="learn-more" role="tab" data-toggle="tab">
              Learn more</a>
        </li>
      </ul>

      <div className="tab-content">
        <div role="tabpanel" className="tab-pane active" id="about">
          <p>
            The Contract Research Map is owned and maintained by Scientist.com.
            It was created to help researchers in the life sciences identify and
            connect with contract research organizations (CROs) based on geography.
            Updated nightly, this map features all of the available CROs within our
            network, so you can order services with a few clicks. Click on a specific
            country, scroll on the map itself or type into the search bar at the
            top—there are many ways to find the location and suppliers that you’re
            looking for. From Argentina to New Zealand, use this map to connect
            with a CRO near you.
          </p>
          <p>
            We believe that every researcher across the world should be able to
            connect with the thousands of global CROs that exist and have the
            opportunity to work together. Like many industries,the life science
            supply chain has been disrupted over the last year. But there are
            many other circumstances such as international customs regulations
            or sensitive shipping times that create limitations around which
            countries are feasible to partner with. Sometimes, finding a CRO based
            in a country that best suits your research needs is imperative. We hope
            this contract research map allows you to find the right partner in the
            right place at the right time.
          </p>
          <p>
            Have questions or feedback? We’d love to help. You can find our FAQs
            and contact information on the Learn more page.
          </p>
        </div>
        <div role="tabpanel" className="tab-pane" id="learn-more">
          <p>
            Interested in connecting with one or more of the contract research
            organizations listed on this map? By clicking on the company’s name,
            you will be directed to their supplier profile on the Scientist.com
            marketplace. Once you set up a marketplace account you can start the
            ordering process immediately.
          </p>

          <h4 className='font-weight-bold mt-3'>What is Scientist.com?</h4>
          <p>
            Scientist.com is the world's largest enterprise marketplace for
            outsourced R&D services. It saves time and money and provides access
            to innovation while maintaining compliance with an organization’s
            procurement policies.
          </p>

          <h4 className='font-weight-bold mt-3'>How can I use Scientist.com?</h4>
          <p>
            Scientist.com has built private, enterprise marketplaces from 24 of
            the 30 largest pharmaceutical companies, 80+ biotech companies, the
            US National Institutes of Health (NIH) and numerous other pharma and
            biotech companies. If you are employed by one of these organizations,
            you can log in to get started today. If you are unsure about how to
            get started, you can email our team at &nbsp;
            <a href="mailto:support@scientist.com">support@scientist.com</a> or go to
            our website &nbsp;
            <a target="_blank" href="https://www.scientist.com">www.scientist.com</a>
            to speak to someone via our live chat.
          </p>

          <h4 className='font-weight-bold mt-3'>Why should I place my order through Scientist.com?</h4>
          <p>
            Scientist.com is a highly efﬁcient enterprise-wide outsourcing marketplace
            that makes it possible for research organizations to save time and
            money, access innovation and ensure compliance. It utilizes a universal
            legal agreement and AI technologies to enable research like never before.
            See how comparing proposals and getting 1-on-1 support from our Research
            Concierge&reg; team will enable you to place more research today.
          </p>

          <h4 className='font-weight-bold mt-3'>How do I get my organization listed on the map?</h4>
          <p>
            If your CRO isn’t showing up on the map, then please be sure your company
            profile is up to date in Scientist.com’s Backoffice. After logging in,
            click the Your Company button in the navigation at the top, and then
            select the Locations tab.
          </p>

          <h4 className='font-weight-bold mt-3'>Information about my organization is incorrect, how do I fix it?</h4>
          <p>
            Head over to <a target="_blank" href="https://backoffice.scientist.com">
            backoffice.scientist.com</a> to update your supplier profile and
            information. It may take up to two business days for the updates to be
            reflected on the map.
          </p>
        </div>
      </div>
    </>
  );

ModalContent.propTypes = {
  actualTab: PropTypes.number.isRequired
};

SiteInfoModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  actualTab: PropTypes.number.isRequired
};

export default SiteInfoModal;