import { createSlice, createSelector } from '@reduxjs/toolkit'
import { isEmpty, isEqual } from 'lodash';

import { fetchLocations } from '../actions/locationActions';

export const initialState = {
  items: [],
  selectedFacet: {
    category: '',
    label: '',
    options: '',
  },
  isFetching: false,
  isError: false,
};

const facetsSlice = createSlice({
  name: 'facets',
  initialState,
  reducers: {
    setFacetOptions: (state, action) => {
      const { category, label, options } = action.payload;
      state.selectedFacet = { category, label, options };
    },
    resetFacets(state) {
      state.selectedFacet = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        const { facets } = action.payload;
        const fixedFacetData = facets && facets.map((facet) => {
          const label = isEmpty(facet.label) ? facet.name.charAt(0).toUpperCase() + facet.name.slice(1) : facet.label;
          return {
            ...facet,
            label,
          }
        }) || [];
        return {
          isFetching: false,
          isError: false,
          items: fixedFacetData || [],
          selectedFacet: (state && state.selectedFacet) ? state.selectedFacet : initialState.selectedFacet
        };
      })
      .addCase(fetchLocations.rejected, () => {
        // will be reported on locations reducer
      })
  },
});

export const facetsListSelector = createSelector(
  (state) => state, // is necessary for selector
  (state) => ({
    isLoadingData: state.locations.isLoadingData,
    facetsData: state.facets.items || [],
    selectedFacet: state.facets.selectedFacet,
    mapConfig: state.mapConfig,
    pageData: state.appConfig.pageData,
  })
);

export const { setFacetOptions, resetFacets } = facetsSlice.actions;
export default facetsSlice.reducer;
