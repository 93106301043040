import React from 'react';
import { Provider } from 'react-redux';

import MainPage from '../containers/MainPageContainer';
import createStore from '../store/appStore';

// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const App = (props) =>
  (
    <Provider store={createStore(props)}>
      <MainPage />
    </Provider>
  );

export default App;
