import React from 'react';
import PropTypes from 'prop-types';

const ResetMap = ({ className }) => 
  <a className={`reset-map btn btn-outline-primary ${className}`} href="/" role="button" aria-roledescription="Reset map">
    <i className="bi bi-arrow-repeat"></i>
    Reset Map
  </a>;

ResetMap.propTypes = {
  className: PropTypes.string
};

export default ResetMap;