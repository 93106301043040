import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const Typeahead = ({ onTextChange, items, maxItems, minChars }) => {
  const [text, setText] = useState('');
  const [displayOptions, setDisplayOptions] = useState(true);

  const textHasMinLength = (text) => {
    if (text && text.length >= (minChars - 1)) {
      return true;
    }
    return false;
  };

  const handleTextChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setText(value);

    if (textHasMinLength(text)) {
      setDisplayOptions(true);
      onTextChange(value);
      return;
    }
    setDisplayOptions(false);
  };

  const renderOptions = () => {
    if (isEmpty(items)) {
      return null;
    }

    return (
      <ul className={`typeahead__options-list`} >
        {
          items.slice(0, maxItems).map((item) => {
            const { name, url, id } = item;
            return (
              <li className='typeahead__options-item' key={id}>
                <a href={url}>{ name }</a>
              </li>
            );
          })
        }
      </ul>
    )
  }

  const onFocus = (e) => {
    setDisplayOptions(true);
  };

  const handleBlur = (e) => {
    // this returns true if the focus when into a child (location link) so we do nothing and let the link to work
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setDisplayOptions(false);
  };

  return (
    <>
      <div className={`typeahead ${displayOptions ? '' : 'hidden-options'}`} onFocus={onFocus} onBlur={handleBlur}>
        <input className='typeahead__search-input form-control' type="text"
          placeholder="Search" aria-label="Search" onChange={handleTextChange} value={text}></input>
        { textHasMinLength(text) && renderOptions() }
      </div>
    </>
  );
}

Typeahead.propTypes = {
  onTextChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })),
  maxItems: PropTypes.number.isRequired,
  minChars: PropTypes.number.isRequired,
};

export default Typeahead;