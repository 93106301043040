import { configureStore } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash';

import appReducer, { initialState as appInitialState } from '../reducers/appReducer';
import { initialState as locationsInitialState, fixLocationData, getLocationDataType } from '../reducers/locationsReducer';
import { initialState as facetsInitialState } from '../reducers/facetsReducer';
import { initialState as mapInitialState, formatMapBounds } from '../reducers/mapConfigReducer';

const fixMapConfigData = (mapConfig) => {  
  const { zoom, action, left, bottom, right, top, latitude, longitude, dynamic } = mapConfig;
  return {
    dynamic: Boolean(dynamic),
    zoom: zoom ? parseInt(zoom) : 0,
    action,
    mapBounds: formatMapBounds({
      north: top,
      east: right,
      south: bottom,
      west: left,
    }),
    latitude: latitude ? parseFloat(latitude) : mapInitialState.latitude,
    longitude: longitude ? parseFloat(longitude) : mapInitialState.longitude,
  };
};

const fixLocationsDataSSR = (features) => (
  Object.assign({}, locationsInitialState, { 
    features: fixLocationData(features),
    dataType: getLocationDataType(features),
  })
);

const createStore = (railsProps) => {
  const {
    appConfig: appConfigRails,
    locations: locationsRails,
    places: placesRails,
    facets: facetsRails,
  } = railsProps;

  const { mapConfig: railsMapConfig } = appConfigRails;

  const mapConfigObj = Object.assign({}, railsMapConfig);
  delete appConfigRails.mapConfig;

  const mapConfigState = fixMapConfigData(mapConfigObj);
  const appConfig = Object.assign({}, appInitialState, appConfigRails);
  const locations = isEmpty(locationsRails.features) ? // render locations (geo points) or places (geo polygons)
    fixLocationsDataSSR(placesRails.features): fixLocationsDataSSR(locationsRails.features);
  const facets = isEmpty(facetsRails) ? {} : Object.assign({}, facetsInitialState, facetsRails);
  const mapConfig = Object.assign({}, mapInitialState, mapConfigState); // load default state

  return configureStore({
    reducer: appReducer,
    preloadedState: {
      facets,
      mapConfig,
      appConfig,
      locations,
    },
  });
};

export default createStore;