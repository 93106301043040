import React from 'react';

const Spinner = () => (
  <div className='spinner'>
      <div className="spinner__icon spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
  </div>
);

export default Spinner;