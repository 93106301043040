import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'lodash';
import { isEmpty } from 'lodash'; 

import { fetchLocations } from '../actions/locationActions';

// I'll try to introduce this difference here:
// POINTS: is a specific "marker" in the map, containst the data for a specific location in the map
// SHAPES: is a "shape" in a choropleth, that's the data used to draw an area in a map

export const LOCATION_DATA_TYPES = {
  'POINTS': 'POINTS',
  'SHAPES': 'SHAPES',
};

const LOCATION_DATA_TYPES_MATCH = { // To Match with the BE values
  'POINT': 'Point',
  'POINTS': 'Points',
  'SHAPES': 'MultiPolygon',
  'SHAPE': 'Polygon',
};

export const initialState = {
  isLoadingData: false,
  isError: false,
  features: [],
  pinnedLocationItem: '',
  activeLocationItem: '',
  dataType: '',
};

const cleanLocations = (locations) => (
  locations.map((location) => {
    const { properties } = location;
    const newProperties = { ...properties };
  
    if (!isEmpty(properties.id)) {
      newProperties.id = properties.id;
    }

    newProperties.snippet = newProperties.snippet || ''; // ToDo: fix this in the backend so we don't have problems in the FE

    return {
      ...location,
      properties: newProperties,
    };
  })
);

const sortLocationsDesc = (locations) => ( // ToDo: Fix this in the BE so we simplify the code in the FE
  sortBy(locations, (location) => (
    location.properties.location_count * -1
  ))
);

export const fixLocationData = (features) => (sortLocationsDesc(cleanLocations(features)));

export const getLocationDataType = (features) => {
  let dataType = '';

  if (isEmpty(features)) {
    return dataType;
  }

  switch(features[0].geometry.type) {
    case LOCATION_DATA_TYPES_MATCH.POINTS:
    case LOCATION_DATA_TYPES_MATCH.POINT:
      dataType = LOCATION_DATA_TYPES.POINTS;
      break;
    case LOCATION_DATA_TYPES_MATCH.SHAPES:
    case LOCATION_DATA_TYPES_MATCH.SHAPE:
      dataType = LOCATION_DATA_TYPES.SHAPES;
      break;
  }

  return dataType;
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    pinLocationItem(state, action) {
      state.pinnedLocationItem = action.payload;
    },

    unpinLocationItem(state) {
      state.pinnedLocationItem = null;
    },

    activateLocationItem(state, action) {
      if (!Boolean(action.payload) || state.activeLocationItem === action.payload) {
        return;
      }
      state.activeLocationItem = action.payload;
    },

    deactivateLocationItem(state) {
      state.activeLocationItem = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.isLoadingData = true;
        state.isError = false;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        const { features } = action.payload;
        const { pinnedLocationItem } = state;
        const newItems = fixLocationData(features)

        let newPinnedLocationItem = pinnedLocationItem;
        if (Boolean(pinnedLocationItem)) {
          const results = newItems.filter((item) => item.properties.id === pinnedLocationItem);
          newPinnedLocationItem = !isEmpty(results) ? newPinnedLocationItem : initialState.pinnedLocationItem;
        }

        return {
          ...state,
          isLoadingData: false,
          isError: false,
          features: newItems,
          dataType: getLocationDataType(newItems),
          pinnedLocationItem: newPinnedLocationItem,
        };
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.isError = true;
        state.isLoadingData = false;
        console.error('rejected', state, action);
      })
  },
});

export default locationsSlice.reducer;
export const { pinLocationItem, unpinLocationItem, activateLocationItem, deactivateLocationItem } = locationsSlice.actions;