import React from 'react';
import PropTypes from 'prop-types';

const NoResults = ({ onResetFacets, onResetFilters, onResetMap }) => {
  const handleResetFacets = () => {
    if (onResetFacets && typeof onResetFacets === 'function') {
      onResetFacets();
    }
  }

  const handleResetFilters = () => {
    if (onResetFilters && typeof onResetFilters === 'function') {
      onResetFilters();
    }
  }

  const handleResetMap = () => {
    if (onResetMap && typeof onResetMap === 'function') {
      onResetMap();
    }
  }

  return (
    <div className='no-results'>
      <span className='no-results__legend'>No Results.</span>
      <div className='no-results__actions'>
        {
          onResetFilters && typeof onResetFilters == 'function' ?
            <a className='facets-selector__reset-action' onClick={handleResetFilters}>
              <i className="bi bi-x-lg"></i>
              Reset Filters
            </a> : <></>
        }
        { (onResetFilters && onResetMap) ? 'or' : '' }
        {
          onResetMap && typeof onResetMap == 'function' ?
            <a className='facets-selector__reset-action' onClick={handleResetMap}>
              <i className="bi bi-x-lg"></i>
              Reset Map
            </a> : <></>
        }
        {
          onResetFacets && typeof onResetFacets == 'function' ?
            <a className='facets-selector__reset-action' onClick={handleResetFacets}>
              <i className="bi bi-x-lg"></i>
              Reset Facets
            </a> : <></>
        }
      </div>
    </div>
  );
}

NoResults.propTypes = {
  onResetFacets: PropTypes.func,
  onResetFilters: PropTypes.func,
  onResetMap: PropTypes.func,
}

export default NoResults;