export const smoothScrollById = (id) => {
  scrollById(id, 'smooth');
};

export const scrollById = (id, behavior) => {
  if (!Boolean(id)) {
    return;
  }

  const element = document.getElementById(id);
  if (!element) {
    console.error('Element with id not found:', id);
    return;
  }

  document.getElementById(id).scrollIntoView({
    block: 'center',
    behavior: behavior
  });
};
