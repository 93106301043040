import React from "react";
import PropTypes from "prop-types";
import { isEmpty, bindAll } from "lodash";

import Spinner from "../../common/Spinner";

const AccordionComponent = ({ selectedFacetsLabel, isLoadingData, handleResetFacets, accordionExpanded, onChange, onBlur }) => {
  const PlaceHolder = () => <div />;

  if (typeof window === "undefined") {
    return <PlaceHolder />;
  }

  // all this lazy imports are needed to do SSR without errors, I guess this libs need window object or other components from the browser.
  // more about React lazy imports: https://reactjs.org/docs/code-splitting.html#reactlazy
  const Accordion = React.lazy(() => import("@mui/material/Accordion"));
  const AccordionDetails = React.lazy(() => import("@mui/material/AccordionDetails"));
  const AccordionSummary = React.lazy(() => import("@mui/material/AccordionSummary"));
  const ExpandMoreIcon = React.lazy(() => import("@mui/icons-material/ExpandMore"));

  const FacetsSelector = React.lazy(() => import("./FacetsSelector"));

  return (
    <React.Suspense fallback={<PlaceHolder />}>
      <Accordion expanded={accordionExpanded} onChange={onChange} onBlur={onBlur}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="facets-content" id="facets-accordion">
          <label className="facets-accordion__label">Filter Results</label>
          <label className="facets-accordion__label facets-accordion__label--selected">
            {selectedFacetsLabel}
            <a
              className="facets-selector__reset-action"
              onClick={handleResetFacets}
            >
              <i className="bi bi-x-lg"></i>
            </a>
          </label>
        </AccordionSummary>
        <AccordionDetails>
          { isLoadingData ? (
            <div className="loading-spinner">
              <Spinner />
            </div>
          ) : (
            <FacetsSelector />
          )}
        </AccordionDetails>
      </Accordion>
    </React.Suspense>
  );
};

class FacetsAccordion extends React.Component {
  constructor(props) {
    super(props);

    const { handleResetFacets, isLoadingData } = props;

    this.state = {
      accordionExpanded: false,
      isLoadingData
    };

    this.handleResetFacets = handleResetFacets;

    bindAll(this, ['onAccordionChange', 'onAccordionBlur']);
  }

  onAccordionChange() {
    const { accordionExpanded } = this.state;
    this.setState({ accordionExpanded: !accordionExpanded });
  }

  onAccordionBlur() {
    this.setState({ accordionExpanded: true })
  };

  render() {
    const { accordionExpanded, isLoadingData } = this.state;
    const { className, selectedFacet } = this.props;

    let selectedFacetsLabel = "";
    const { options, category, label } = selectedFacet;

    if (!isEmpty(category) && !isEmpty(options)) {
      selectedFacetsLabel = `${label}: ${options.join(",")}`;
    }

    return (
      <section 
        className={`facets-accordion ${className || ""}
        ${ isEmpty(selectedFacetsLabel) ? "" : "facets-accordion--selected-facets" }`}
      >
        <AccordionComponent
          selectedFacetsLabel={selectedFacetsLabel}
          isLoadingData={isLoadingData}
          handleResetFacets={this.handleResetFacets}
          accordionExpanded={accordionExpanded}
          onChange={this.onAccordionChange}
          onBlur={this.onAccordionBlur}
        />
      </section>
    );
  }
};

FacetsAccordion.propTypes = {
  className: PropTypes.string,
};

export default FacetsAccordion;
