import * as bootstrap from "bootstrap"; // do not remove, this enables bootstrap in all components
import React from 'react';
import { isEmpty, bindAll, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import Footer from './Footer';
import MapTools from './MapTools';
import ResearchMap from './ResearchMap';
import LocationsService from '../services/locationsService';
import { smoothScrollById, scrollById } from '../utils/utils';
import { LocationItemShape, mapShape } from '../shapes/shapes';

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      pinnedLocationItem,
      activeLocationItem,
      // functions
      pinLocationAction,
      unpinLocationAction,
      deactivateLocationItem,
      fetchLocations,
      updateMapState,
    } = props;

    this.state = {
      activeLocationItem,
      pinnedLocationItem,
      myResearchMap: {},
      mapLoaded: false,
      openMapMarker: '',
      locationsSliderOpen: false,
    };

    this.pinLocationAction = pinLocationAction;
    this.unpinLocationAction = unpinLocationAction;
    this.deactivateLocationItem = deactivateLocationItem;
    this.fetchLocations = fetchLocations;
    this.updateMapState = updateMapState;

    bindAll(this, [
      'onMapLoaded',
      'onMoveEnd',
      'onMapClick',
      'onMarkerClick',
      'getNewData',
      'onCloseSidePanel',
      'onMapUpdated',
      'getActualMapConfig',
      'onPinnedLocationItemChange',
      'onActiveLocationItemChange',
    ]);
  }

  onCloseSidePanel() {
    this.setState({
      locationsSliderOpen: false
    })
  }

  componentDidMount() {
    this.setState({
      myResearchMap: this.createMapInstance()
    });
  }

  componentWillUnmount(state) {
    // console.log('componentWillUnmount', state);
    // ToDo: remove listeners here and in every component
  }

  // ToDo: refactor this function adding >  updateMapFromNewProps() {}
  componentDidUpdate(prevProps) {
    const { locationItems, locationsDataType, activeLocationItem, pinnedLocationItem } = this.props;
    const { locationItems: prevLocationItems, activeLocationItem: previousActiveLocationItem, pinnedLocationItem:previousPinnedLocationItem } = prevProps;

    if(!isEqual(locationItems, prevLocationItems)) {
      this.updateMapLocations({ locationItems, locationsDataType });
      return;
    }

    if(!isEqual(activeLocationItem, previousActiveLocationItem)) {
      this.onActiveLocationItemChange(activeLocationItem, pinnedLocationItem);
    }

    if(!isEqual(pinnedLocationItem, previousPinnedLocationItem)) {
      this.onPinnedLocationItemChange(pinnedLocationItem);
    }

    return;
 }

  createMapInstance() {
    const { mapboxAccessToken, mapConfig, locationItems, locationsDataType } = this.props;
    const { zoom, latitude, longitude, action, mapBounds, dynamic } = mapConfig;
    const { west, south, east, north } = mapBounds;

    let center = [-74.5, 40]; // starting position [lng, lat], our center of the world

    // CONSTANTS
    const DEFAULT_MAP_CONFIG = {
      container: 'map',
      zoomControl: true,
      dragRotate: false,
      touchZoomRotate: false,
      dynamic,
      onMapLoaded: this.onMapLoaded,
      onMoveEnd: this.onMoveEnd,
      onMapClick: this.onMapClick,
      onMarkerClick: this.onMarkerClick,
      onMapUpdated: this.onMapUpdated,
    };

    const initialMapConfig = {
      mapData: locationItems,
      dataType: locationsDataType,
      mapboxAccessToken,
    };

    switch (action) {
      case 'search':
        initialMapConfig.center = Boolean(longitude) & Boolean(latitude) ? [longitude, latitude] : center;
        initialMapConfig.zoom = zoom;
        break;
      case 'bounding_box':
        initialMapConfig.bounds = [[west, south], [east, north]];
        break;
    }
 
    return new ResearchMap(Object.assign({}, DEFAULT_MAP_CONFIG, initialMapConfig));
  }

  getActualMapConfig() {
    const { myResearchMap } = this.state;
    const mapBounds = myResearchMap.getMapBounds();

    return {
      zoom: myResearchMap.getMapZoom(),
      mapType: myResearchMap.mapType,
      mapBounds
    };
  }

  onMapUpdated() {
    const { pinnedLocationItem } = this.props;
    const { zoom, mapBounds, mapType } = this.getActualMapConfig();

    this.updateMapState(zoom, mapBounds, mapType);
    this.openLocationsSlider();

    if (Boolean(pinnedLocationItem)) {
      setTimeout(() => scrollById(pinnedLocationItem), 100);
    }
  }

  updateBrowserUrl() {
    const { mapIsDynamic: isMapPinned } = this.state;
    const { pageData } = this.props;
    const { zoom, mapBounds } = this.getActualMapConfig();
    const newUrl = LocationsService.getNewBrowserUrl({ pageData, zoom, mapBounds });

    // ToDo: It needs to validate if it's clientside code because it's using window object.
    if(window && window.location.href.indexOf(newUrl) == -1 && !isMapPinned) {
      if (history.pushState) {
        history.pushState({}, '', newUrl);
      }
    }
  }

  getNewData(){
    const { zoom, mapBounds } = this.getActualMapConfig();
    const { pageData, selectedFacet } = this.props;

    this.updateBrowserUrl();
    this.fetchLocations({ pageData, zoom, mapBounds, selectedFacet });
  };

  hasMarkers(data) {
    if(data && data[0] && data[0]['geometry']
      && data[0]['geometry']['type'] == 'Point') {
      return true;
    }
    return false;
  };

  // Map Functions
  onMapClick(ev) {
    this.unpinLocationAction();
    this.deactivateLocationItem();
  }

  onMarkerClick(ev, onMarkerClick) {
    const selectedLocationId = onMarkerClick && onMarkerClick.id;
    this.unpinLocationAction();
    this.pinLocationAction(selectedLocationId);
    smoothScrollById(selectedLocationId);
    this.openLocationsSlider();
  }

  openLocationsSlider() {
    setTimeout(() => {
      this.setState({ locationsSliderOpen: true }); // This will open the locations's slider in case it is closed
    });
  }

  onMapLoaded() {
    this.setState({ mapLoaded: true });
  };

  onMoveEnd() {
    const { mapLoaded } = this.state;
    const { mapConfig, } = this.props;

    if(mapLoaded && Boolean(mapConfig.dynamic)) {
      this.getNewData();
    }
  };

  // update map data on locations update
  updateMapLocations({ locationItems, locationsDataType }) {
    const { myResearchMap } = this.state;
    const { activeLocationItem, pinnedLocationItem } = this.props;

    if (isEmpty(locationItems) || isEmpty(myResearchMap)) {
      return;
    }

    myResearchMap.setData({
      mapData: locationItems,
      dataType: locationsDataType,
    });
  }

  onPinnedLocationItemChange(pinnedLocationItemId) {
    const { myResearchMap } = this.state;

    if (isEmpty(myResearchMap)) {
      return;
    }

    myResearchMap.highlightLocation(pinnedLocationItemId)
  }

  onActiveLocationItemChange(activeLocationItem) {
    const { myResearchMap, } = this.state;
    const { pinnedLocationItem } = this.props;

    if (isEmpty(myResearchMap)) {
      return;
    }

    if (Boolean(pinnedLocationItem)) { // if there is a pinned item, ignore the hover event
      return;
    }

    myResearchMap.closeOpenMarkerPopup();
    setTimeout(() => myResearchMap.deactivateMarker());

    if (Boolean(activeLocationItem)) {
      setTimeout(() => myResearchMap.activateMarker(activeLocationItem));
    }
  }

  render () {
    const  { locationItems, facets, pinnedLocationItem, isLoadingData, adsEnabled, adsMaxCount } = this.props;
    const { locationsSliderOpen } = this.state;

    return (
      <>
        <div id="page-content">
          <MapTools
            locationsSliderOpen={locationsSliderOpen}
            locationItems={locationItems}
            facets={facets}
            pinnedLocationItem={pinnedLocationItem}
            isLoadingData={isLoadingData}
            hasMarkers={this.hasMarkers(locationItems)}
            adsEnabled={adsEnabled}
            adsMaxCount={adsMaxCount}
            onCloseSidePanel={this.onCloseSidePanel}
          />
          <div id="map"></div>
        </div>
        <Footer />
      </>
    );
  }
}

MainPage.propTypes = {
  pinLocationAction: PropTypes.func.isRequired,
  unpinLocationAction: PropTypes.func.isRequired,
  deactivateLocationItem: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  updateMapState: PropTypes.func.isRequired,
  
  activeLocationItem: PropTypes.string,
  locationItems: PropTypes.arrayOf(PropTypes.shape(LocationItemShape)),
  locationsDataType: PropTypes.string,
  facets: PropTypes.array.isRequired,
  pinnedLocationItem: PropTypes.string,
  isLoadingData: PropTypes.bool.isRequired,
  selectedFacet: PropTypes.objectOf(PropTypes.any),
  mapConfig: PropTypes.shape(mapShape).isRequired,
  adsEnabled: PropTypes.bool,
  adsMaxCount: PropTypes.number,
};

export default MainPage;