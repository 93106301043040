import React from 'react';
import PropTypes from 'prop-types';

const GenericModal = ({ id, title, children }) => (
  <div className="modal" tabIndex="-1" id={id}>
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"> {title} </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          { children }
        </div>
      </div>
    </div>
  </div>
);

GenericModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
export default GenericModal;