import PropTypes from 'prop-types';

export const LocationItemShape = {
  id: PropTypes.number,
  geometry: PropTypes.shape({
    coordinates: PropTypes.array,
    type: PropTypes.string.isRequired,
  }).isRequired,
  properties: PropTypes.shape({
    location_count: PropTypes.number,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    snippet: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export const mapShape = {
  ready: PropTypes.bool.isRequired,
  zoom: PropTypes.number.isRequired,
  mapBounds: PropTypes.shape({
    west: PropTypes.number.isRequired,
    south: PropTypes.number.isRequired,
    east: PropTypes.number.isRequired,
    north: PropTypes.number.isRequired,
  }),
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  mapType:  PropTypes.string.isRequired,
};

export const adShape = {
  adId: PropTypes.number.isRequired,
  clickUrl: PropTypes.string.isRequired,
  impressionUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};