import fetch from '../utils/fetch';
import { isEmpty } from 'lodash';
class LocationsService {
  static getDataUrl(pageData, zoom, { west, south, east, north }) {
    const PAGE_TYPES = {
      LOCATION: 'LOCATION',
      PLACE: 'PLACE',
      PROVIDER: 'PROVIDER',
      ANNOTATION: 'ANNOTATION'
    }

    let mapUrl = '';

    switch (pageData.type) {
      case PAGE_TYPES.PLACE:
        mapUrl = `/places/${pageData.value}`;
        break;
      case PAGE_TYPES.PROVIDER:
        mapUrl = `/providers/${pageData.value}`;
        break;
      case PAGE_TYPES.ANNOTATION:
        mapUrl = `/annotations/${pageData.value}`;
        break;
      default:
        mapUrl = `/locations/${zoom}/${west}/${south}/${east}/${north}`;
    }

    return mapUrl;
  }

  static getFacetsUrlSection(selectedFacets) {
    let url = '';
    if (isEmpty(selectedFacets) || isEmpty(selectedFacets.options)) {
      return url;
    }

    const { category, options } = selectedFacets;
    const facetsOptions = options.map((option) => {
      return `${category}[]=${option}`;
    });
    const facetsPart = `?${facetsOptions.join('&')}`;
    return encodeURI(facetsPart);
  }

  static getNewBrowserUrl({ pageData, zoom, mapBounds, selectedFacets }) {
    const url = LocationsService.getDataUrl(pageData, zoom, mapBounds);
    return `${url}`;
  }

  static getNewData(pageData, zoom, mapBounds, selectedFacets) {
    const url = LocationsService.getDataUrl(pageData, zoom, mapBounds);
    let facetsUrl = LocationsService.getFacetsUrlSection(selectedFacets);
    let fullJSONUrl = `${url}.json${facetsUrl || ''}`;
  
    return fetch(fullJSONUrl)
      .then((response) => ({response}))
  };  
};

export default LocationsService;