import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LocationItemShape } from '../../shapes/shapes';

// ------------------
// Not used Attributes
// ------------------
// color: "#3da637" // ToDo: is it necessary?
// extra_class: "zero-vendors"
// fill: true
// fillColor: "#3da637"
// fillOpacity: 0
// stroke: true

// ------------------
// Used Attributes
// ------------------
// location_count: 0
// name: "Afghanistan"
// slug: "afghanistan"
// snippet: "0 vendors"
// subtitle: "country"
// type: "Places"
// url: "/places/afghanistan"

const Item = ({ data, onClick, onMouseEnter, onMouseLeave, isPinned }) => {
  const { name, snippet, subtitle, url, id, itemType } = data.properties;

  const clickHandler = (ev) => {
    const itemElement = ev.target.closest('.item');
    onClick(itemElement.dataset);
  }; 

  const mouseEnterHandler = (ev) => {
    const itemElement = ev.target.closest('.item');
    onMouseEnter(itemElement.dataset.id);
  };

  const mouseLeaveHandler = (ev) => {
    onMouseLeave(ev);
  }

  return (
    <div  className={`item ${isPinned ? 'item__pinned' : ''}`} id={id} data-id={id} data-url={url} data-type={itemType}
      onClick={clickHandler} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} >
      <div className="item__content">
        <div className='item__title'>
          { isPinned ?
              <i className="bi bi-pin-angle pin-icon"></i> : <></>
          }
          {name}
        </div>
        <p className='item__description'>
          <span className='item__subtitle'>{subtitle}</span>
          <span className='item__snippet'>{snippet}</span>
        </p>
      </div>
      {
        itemType === 'point' ?
        <div className='item__links'>
          <a className="item__link" href={url} target="_blank">
            <i className="icon bi bi-box-arrow-up-right"></i>
            <span className="item__link-label" aria-roledescription='label'>More</span>
          </a>
        </div> : <></>
      }
    </div>
  )
};

const ListItems = ({ items, onItemClick, onMouseEnterItem, onMouseLeaveItem, pinnedItem }) => {
  return (
    <>
      <div className="list-items">
        <div className="list-items__wrapper">
          {
            items.map((item, idx) => {
              const itemId = item && item.properties && item.properties.id ? item.properties.id : null;
              const isTypePoint = item && item.properties && item.properties.itemType === 'point' ? true : false;
              return (
                <Item
                  data={item}
                  key={idx}
                  onClick={onItemClick}
                  onMouseEnter={isTypePoint ? onMouseEnterItem : () => {}}
                  onMouseLeave={isTypePoint ? onMouseLeaveItem : () => {}}
                  isPinned={Boolean(pinnedItem) && pinnedItem === itemId}
                ></Item>
              );
            })
          }
        </div>
      </div>
    </>
  );
}

ListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(LocationItemShape)),
  onItemClick: PropTypes.func.isRequired,
  onMouseEnterItem: PropTypes.func.isRequired,
  onMouseLeaveItem: PropTypes.func.isRequired,
  pinnedItem: PropTypes.string,
};

export default ListItems;