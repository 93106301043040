import React from 'react';
import PropTypes from 'prop-types';

import { LocationItemShape } from '../../shapes/shapes';

const ListNameFilter = ({ textChangeFilter, listItems, textValue, isLoadingData }) => {
  const disableInput = (!textValue && Boolean(listItems && listItems.length <= 0)) || isLoadingData;
  const textChangeHandler = (ev) => {
    const newText = ev.target.value.toLowerCase();
    const matchingItems = listItems.filter((item) => item.properties.name.toLowerCase().includes(newText));
    textChangeFilter({ newText, matchingItems });
  };

  return (
    <div className='list-filter'>
      <label className='list-filter__label' htmlFor="">Filter by Name:</label>
      <input type='text' className='list-filter__input' 
        onChange={textChangeHandler}
        value={textValue}
        disabled={disableInput ? 'disabled' : ''}
      ></input>
    </div>
  );
};

ListNameFilter.propTypes = {
  textChangeFilter: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape(LocationItemShape)),
  textValue: PropTypes.string,
  isLoadingData: PropTypes.bool.isRequired,
};

export default ListNameFilter;

