import mapboxgl from '!mapbox-gl';
import { find, isEmpty, throttle, findIndex, bindAll } from 'lodash';
import { LOCATION_DATA_TYPES } from '../reducers/locationsReducer';

class ResearchMap {
  constructor(props) {
    const {
      container,
      center,
      zoom,
      bounds,
      mapData,
      dataType,
      zoomControl,
      onMoveEnd,
      dynamic,
      onMapLoaded,
      mapboxAccessToken,
      onMapClick,
      onMarkerClick,
      onMapUpdated,
    } = props;

    // MATCH THIS WITH LOCATION TYPES
    // UPDATE REDUX MAP TYPE: WHEN THIS IS SET
    this.MAP_TYPES = {
      CHOROPLETH: 'CHOROPLETH',
      MARKERS: 'MARKERS',
      CLUSTERS: 'CLUSTERS',
    };

    this.CLUSTER_VARIABLES = {
      SOURCE_NAME: 'locations-cluster',
      LAYER_ID: 'clusters',
      COUNT_LAYER_ID: 'clusters-count',
      UNCLUSTERED_SOURCE: 'unclustered-points',
      UNCLUSTERED_POINT_LAYER_ID: 'unclustered-point',
      MAX_ZOOM: 11,
      HIGHLIGHT_COLOR: '#dc3545'
    };

    this.CLUSTER_DATA = {
      mapLocationsCluster: {},
      highlighted: {},
    }

    this.mapData = mapData;
    this.dataType = dataType || null,
    this.mapType =  null,
    this.dynamic = dynamic;
    this.zoomControl = zoomControl || false;
    this.onMoveEnd = onMoveEnd;
    this.renderedPlaces = [];
    this.renderedMarkers = [];
    this.onMapLoaded = onMapLoaded;
    this.onMapClick = onMapClick;
    this.onMarkerClick = onMarkerClick;
    this.zoom = zoom;
    this.onMapUpdated = onMapUpdated;
    this.mapLoaded = false;
    this.ignoreMoveEndOnce = false;
    this.highlightedLocation = {}; // id: '', mapType: ''
    this.isMapReady = false; // will switch true/false on new dataloading

    this.mapInstance = {};
    this.globalPopup = {};
    this.activeMarker = {};
    this.tempPopup = {};

    bindAll(this, [
      'onMapLayerClickHandler',
      'mapLoadedHandler',
      'mapMoveEnd',
      'onMapClickHandler',
      'closeOpenMarkerPopup',
      'openMarkerPopupById',
      'createMarker',
      'activateMarker',
      'deactivateMarker',
      'highlightClusterContainingLocation',
      'mapAllLocationsInClusters',
      'saveClusterLocationsRelation',
      'unhighlightActualCluster',
      'findCloseCluster',
      'findClustersAroundPosition',
      'getClusterForPosition',
      'silentMoveMapTo',
      'highlightClusterWhenMapStopsMoving',
      'renderTempMarker',
      'removeTempClusterMarker',
      'unhighlightActualLocation',
      'renderData',
      'onMapUpdatedHandler',
      'getMapZoom',
      'openTempMarkerPopup',
      'createTempMarker',
      'updateMapView',
    ]);

    this.init({ container, center, zoom, bounds, mapboxAccessToken, mapData, dataType });
  }

  init(props) {
    const { container, center, zoom, bounds, mapboxAccessToken, mapData, dataType } = props;

    if (!mapboxgl) {
      console.error('No mapbox library detected');
      return;
    }

    // constants
    const style = 'mapbox://styles/mapbox/streets-v11';
    const maxZoom = 17;
    const minZoom = 1;
    const mapConfig = { container, style, maxZoom, minZoom };

    if (bounds) { // bounds feature overwrites center and zoom, we should not combine
      mapConfig.bounds = bounds;
    } else {
      if (center) {
        mapConfig.center = center;
        mapConfig.zoom = zoom;
      }
    }

      mapboxgl.accessToken = mapboxAccessToken;
      this.mapInstance = new mapboxgl.Map(mapConfig);

    this.setMapEvents();
    this.setData({ mapData, dataType });

    if (this.zoomControl) {
      this.addZoomControl();
    }
  }

  renderPolygons() {
    const places = this.mapData;

    this.cleanOldRenderedPlaces()
    this.unhighlightActualLocation();
    this.removeTempClusterMarker();
    this.removeAllMarkers();
    this.removeClusters();

    places.forEach((place) => {
      const { properties } = place;
      const { name, location_count, fillColor, fillOpacity } = properties;

      if (location_count <= 0) {
        return;
      }

      this.renderedPlaces.push(place);

      const source = this.mapInstance.getSource(name);
      if (source !== undefined) {
        this.addClickEventForLayer(name);
        return;
      }

      this.mapInstance.addSource(name, {
        type: "geojson",
        data: place,
      });

      // Add a new layer to visualize the polygon.
      this.mapInstance.addLayer({
        id: name,
        type: "fill",
        source: name,
        layout: {},
        paint: {
          "fill-color": fillColor || '#3da637',
          "fill-opacity": fillOpacity || 0,
        },
      });
      this.addClickEventForLayer(name);
    });
  }

  removeListenerForLayer(name) {
    this.mapInstance.off("click", name, this.onMapLayerClickHandler);
  }

  addClickEventForLayer(name) {
    this.mapInstance.on("click", name, this.onMapLayerClickHandler);
  }

  // if the place is rendered and we found it in the new list to render, keep it
  // if not remove it
  cleanOldRenderedPlaces() {
    const newPlaces = this.mapData;
    this.renderedPlaces.forEach((renderedPlace) => {
      const { name: renderedPlaceName } = renderedPlace.properties;

      const keepItRendered = find(newPlaces, (newPlace) => {
        return newPlace.properties.name === renderedPlaceName;
      });

      if (keepItRendered) {
        return;
      }

      if (this.mapInstance.getLayer(renderedPlaceName)) {
        this.mapInstance.removeLayer(renderedPlaceName);
      }

      if (this.mapInstance.getSource(renderedPlaceName)) {
        this.mapInstance.removeSource(renderedPlaceName);
      }
    });

    this.renderedPlaces = [];
  }

  cleanAllChloropethLayers() {
    this.renderedPlaces.forEach((renderedPlace) => {
      const { name: renderedPlaceName } = renderedPlace.properties;
      if (this.mapInstance.getLayer(renderedPlaceName)) {
        this.mapInstance.removeLayer(renderedPlaceName);
      }

      if (this.mapInstance.getSource(renderedPlaceName)) {
        this.mapInstance.removeSource(renderedPlaceName);
      }
    });
  }

  removeMarker(marker) {
    marker.getElement().removeEventListener('click', marker.listener);
    marker.remove();
  }

  // if the marker is rendered and we found it in the new list to render, just keep it
  // if not remove it
  removeOldMarkers(renderedMarkers, newMarkersList) {
    const markersToKeep = [];
    const markersToRemove = [];

    renderedMarkers.forEach((renderedMarker) => {
      const renderedMarkerId = renderedMarker.properties.id;
      const keepIt = findIndex(newMarkersList, (setMarker) => setMarker.properties.id === renderedMarkerId) >= 0;

      if (keepIt) {
        markersToKeep.push(renderedMarker);
      } else {
        markersToRemove.push(renderedMarker);
      }
      return;
    });
    !isEmpty(this.activeMarker) && this.activeMarker.remove();
    markersToRemove.forEach((oldMarker) => this.removeMarker(oldMarker));

    return markersToKeep;
  }

  createMissingMarkers(existentMarkers, newMarkers) {
    const createdMarkers = [];
    newMarkers.forEach((newMarker) => {
      const createIt = findIndex(existentMarkers, (existentMarker) => existentMarker.properties.id === newMarker.properties.id ) < 0;
      if (createIt) {
        createdMarkers.push(this.createMarker(newMarker));
      }
    });
    return createdMarkers;
  }

  removeAllMarkers() {
    this.renderedMarkers.forEach(this.removeMarker);
    this.renderedMarkers = [];
  }

  setMarkers() {
    const setMarkersList = this.mapData;
    const { renderedMarkers } = this;
    let finalMarkers = [];

    this.cleanAllChloropethLayers();
    this.removeClusters();

    if (renderedMarkers.length > 0) {
      const markersToKeep = this.removeOldMarkers(renderedMarkers, setMarkersList);
      const createdMarkers = this.createMissingMarkers(markersToKeep, setMarkersList);
      finalMarkers = [...markersToKeep, ...createdMarkers];
    } else {
      const createdMarkers = setMarkersList.map((markerData) => { return this.createMarker(markerData); });
      finalMarkers = createdMarkers;  
    }

    this.renderedMarkers = finalMarkers;

    if (isEmpty(this.highlightedLocation)) {
      return;
    }

    this.mapInstance.once('idle', () => this.highlightLocation(this.highlightedLocation.id));
  }

  createMarker(newMarker) {
    const { properties, geometry } = newMarker;
    const { id } = properties;
    const markerColor = properties["marker-color"];
    const lngLat = !isEmpty(geometry) && !isEmpty(geometry.coordinates) ? [geometry.coordinates[0], geometry.coordinates[1]] : [0,0];

    const newMarkerElement = new mapboxgl.Marker({ color: markerColor })
      .setLngLat(lngLat)
      .addTo(this.mapInstance);
    newMarkerElement.id = id;
    newMarkerElement.properties = properties;
    newMarkerElement.geometry = geometry;

    // -----------------------------------------------------------------------------------------------
    // Notes on Popups
    // -----------------------------------------------------------------------------------------------
    // I had to redesign all the popup features, there is more than one way to attach popups to a map,
    // and they do not play well between them:
    //   Problem: If you open the popup by clicking the marker one popup opens, if you call the mapbox
    //     function marker.togglePopup() instead of using the same popup (toggling), a different popup is created
    //     and you are forced to close both to make them dissapear.
    //   Solution: I had to force the app to do not create popups on click instead all popups are created
    //     using the same mechanism: everytime redux data changes openMarkerPopupById it's called and it
    //     creates our custom popup using mapboxgl.Popup and no more: marker.togglePopup(), so we always
    //     have a single popup, for more info see function: openMarkerPopupById below

    newMarkerElement.listener = this.onMarkerClickHandler;
    newMarkerElement.getElement().addEventListener('click', (ev) => { this.onMarkerClickHandler(ev, newMarkerElement) });

    return newMarkerElement;
  }

  onMarkerClickHandler(ev, marker) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.onMarkerClick && typeof this.onMarkerClick === 'function') {
      this.onMarkerClick(ev, marker);
    }
  }

  removeMapCustomEventListener() {
    this.mapInstance.off('click', this.onMapClickHandler);
  }

  setMapCustomEventListener() {
    this.mapInstance.on('click', this.onMapClickHandler);
  }

  cleanUpListeners(places) {
    places.forEach((place) => {
      const { properties } = place;
      const { name } = properties;
      this.removeListenerForLayer(name);
    });
  }

  getMapType(dataType, actualZoom) {
    const { POINTS, SHAPES } = LOCATION_DATA_TYPES;
    const { CHOROPLETH, CLUSTERS, MARKERS } = this.MAP_TYPES;
    const { MAX_ZOOM } = this.CLUSTER_VARIABLES;

    let mapType = '';
    if (dataType === '') {
      return '';
    }

    switch(dataType) {
      case SHAPES:
        mapType = CHOROPLETH;
        break;
      case POINTS:
        mapType = CLUSTERS;

        if (actualZoom > MAX_ZOOM) {
          mapType = MARKERS;
        }
        break;
    }
    return mapType;
  }

  setData(props) {
    const { mapData: newData, dataType } = props;
    const { mapData } = this;

    if (isEmpty(newData)) {
      console.error(`No Data Found for location.`);
      return;
    }

    if (!isEmpty(mapData)) {
      this.cleanUpListeners(mapData);
    }

    if (this.mapInstance.isStyleLoaded()) {
      const actualZoom = this.getMapZoom();
      this.mapData = newData;
      this.dataType = dataType || '';
      this.mapType = this.getMapType(dataType, actualZoom);
      this.renderData();
      this.onMapUpdatedHandler();
    } else {
      setTimeout(() => {
        this.setData(props)
      }, 100);
    }
  }

  onMapUpdatedHandler() {
    if (!Boolean(this.onMapUpdated) || typeof this.onMapUpdated != 'function') {
      return;
    }
    this.mapInstance.once('idle', () => {
      this.onMapUpdated();
    });
  }

  addZoomControl() {
    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
    });
    this.mapInstance.addControl(nav, 'bottom-left');
  }

  renderData() {
    const { mapType, MAP_TYPES } = this;
    const { CHOROPLETH, CLUSTERS, MARKERS } = MAP_TYPES;

    let highlight = false;
    switch(mapType) {
      case CHOROPLETH:
        this.renderPolygons();
      break;
      case CLUSTERS:
        highlight = Boolean(this.highlightedLocation.id);
        this.renderClusters();
        break;
        case MARKERS:
        highlight = Boolean(this.highlightedLocation.id);
        this.setMarkers();
      break;
    }

    const locationId = this.highlightedLocation.id;
    if (highlight) {
      this.mapInstance.once('idle', () => this.highlightLocation(locationId));
    }
  }

  getMapBounds() {
    const { mapInstance } = this;
    return {
      north: mapInstance.getBounds().getNorth(),
      south: mapInstance.getBounds().getSouth(),
      west: mapInstance.getBounds().getWest(),
      east: mapInstance.getBounds().getEast(),
    };
  }

  setMaxBounds() {
    const { west, south, east, north } = this.getMapBounds();
    const bounds = [[west, south], [east, north]];
    this.mapInstance.setMaxBounds(bounds);
  }

  mapLoadedHandler() {
    if (this.mapInstance.isZooming()) {
      setTimeout(() => {
        this.mapLoadedHandler();
      }, 500);
      return;
    }

    this.mapLoaded = true;

    if(!this.dynamic) {
      this.setMapBounds()
    }

    if (this.onMapLoaded) {
      this.onMapLoaded(this);
    }
  }

  updateMapView() {
    const { mapType, mapData, dataType } = this;
    const newMapType = this.getMapType(dataType, this.getMapZoom());

    if(mapType === newMapType) {
      return;
    }

    // force a view update with same data
    this.setData({
      mapData: mapData,
      dataType: dataType,
    });
  }

  mapMoveEnd() {
    if (!this.onMoveEnd) {
      return;
    }

    if (Boolean(this.ignoreMoveEndOnce)) {
      this.ignoreMoveEndOnce = false;
      return;
    }

    if (!Boolean(this.dynamic)) { // on dynamic === false, do not load new data, just switch view between markers and clusters
      this.updateMapView();
    }

    this.onMoveEnd(this);
  }

  setMapBounds() {
    this.mapInstance.once('zoomend', () => {
      this.setMaxBounds();
    });
    this.setMapZoom();
  }

  getMapZoom() {
    return Math.floor(this.mapInstance.getZoom());
  }

  setMapZoom() {
    const actualZoom = this.getMapZoom();
    const newZoom = actualZoom * .95;
    this.mapInstance.setZoom(newZoom, { duration: 200 });
  }

  setMapEvents() {
    this.mapInstance.on("load", () => {
      this.isMapReady = true;
      this.mapLoadedHandler();
    });

    this.mapInstance.on("dataloading", () => {
      this.isMapReady = false;
    });

    this.mapInstance.on("idle", () => {
      this.isMapReady = true;
    });

    this.setMapCustomEventListener();

    const moveEndThrottled = throttle(() => { this.mapMoveEnd(); }, 1000, { 'trailing': false });
    this.mapInstance.on("moveend", moveEndThrottled);
  }

  unpinMap() {
    this.dynamic = true;
    this.mapInstance.setMaxBounds(null);
  }

  getPopupTemplate({ subtitle, id, name }) {
    return `
      <div class="location-popup" ${id ? `data-id="${id}"` : ''}>
        <h6 class="location-popup__heading">
          ${name}
        </h6>
        <p class='location-popup__subtitle'>${subtitle}</p>
      </div>
    `;
  }

  getMapMarker(markerID) {
    const { renderedMarkers } = this;

    if (!markerID) {
      return;
    }

    const mapMarker = find(renderedMarkers, (marker) => { 
      return marker.id === markerID;
    });

    if (isEmpty(mapMarker)) {
      return {};
    }

    return mapMarker;
  }

  getPopUp(lngLat, properties) {
    const popupContent = this.getPopupTemplate({ ...properties });
    return new mapboxgl.Popup({
        offset: [0, -15],
        focusAfterOpen: false,
        closeButton: false,
      })
      .setLngLat(lngLat)
      .setHTML(`${popupContent}`);
  }

  activateMarker(markerId) {
    if (!Boolean(markerId)) {
      return;
    }

    const marker = this.getMapMarker(markerId);
    const clonedMarker = {
      ...marker,
      properties: {
        ...marker.properties,
        "marker-color": '#dc3545'
      },
    };

    const redMarker = this.createMarker(clonedMarker);
    this.activeMarker = redMarker;
  }

  deactivateMarker() {
    if (isEmpty(this.activeMarker)) {
      return;
    }
    this.activeMarker.getElement().removeEventListener('click', this.activeMarker.listener);
    this.activeMarker.remove();
  }

  openMarkerPopupById(markerId) {
    if (!Boolean(markerId)) {
      return;
    }
    const mapMarkerToOpen = this.getMapMarker(markerId);

    if (isEmpty(mapMarkerToOpen)) {
      return;
    }

    this.deactivateMarker();
    this.closeOpenMarkerPopup();

    this.highlightedLocation = {
      id: markerId,
      mapType: this.mapType,
    };

    const { geometry } = mapMarkerToOpen;
    const lngLat = [geometry.coordinates[0], geometry.coordinates[1]];
    const globalPopup = this.getPopUp(lngLat, mapMarkerToOpen.properties);
    this.globalPopup = globalPopup;

    // the open and removal of popups are asyncronous so they trigger problems if we execute them without setTimeout
    setTimeout(() => {
      this.activateMarker(markerId);
      globalPopup.addTo(this.mapInstance);
    }, 100);
  }

  closeOpenMarkerPopup() {
    const { globalPopup } = this;
    // the open and removal of popups are asyncronous so they trigger problems if we execute them without setTimeout
    setTimeout(() => {
      !isEmpty(globalPopup) && globalPopup.remove();
    });
    this.globalPopup = {};
  }

  onMapClickHandler(ev) {
    this.unhighlightActualLocation();
    if (this.onMapClick && typeof this.onMapClick === 'function') {
      this.onMapClick(ev);
    }
  }

  // When a click event occurs on a feature in the places layer, open a popup at the
  // location of the feature, with description HTML from its properties.
  onMapLayerClickHandler(e) {
    const { features, lngLat } = e;
    this.getPopUp(lngLat, features[0].properties).addTo(this.mapInstance);
  }

  removeClusters() {
    const { SOURCE_NAME, LAYER_ID, COUNT_LAYER_ID, UNCLUSTERED_SOURCE, UNCLUSTERED_POINT_LAYER_ID } = this.CLUSTER_VARIABLES;

    if (this.mapInstance.getLayer(LAYER_ID)) {
      this.mapInstance.removeLayer(LAYER_ID);
    }

    if (this.mapInstance.getLayer(COUNT_LAYER_ID)) {
      this.mapInstance.removeLayer(COUNT_LAYER_ID);
    }

    if (this.mapInstance.getLayer(UNCLUSTERED_POINT_LAYER_ID)) {
      this.mapInstance.removeLayer(UNCLUSTERED_POINT_LAYER_ID);
    }

    if (this.mapInstance.getSource(SOURCE_NAME)) {
      this.mapInstance.removeSource(SOURCE_NAME);
    }

    if (this.mapInstance.getSource(UNCLUSTERED_SOURCE)) {
      this.mapInstance.removeSource(UNCLUSTERED_SOURCE);
    }

    if (!isEmpty(this.CLUSTER_DATA.mapLocationsCluster)) {
      this.CLUSTER_DATA.mapLocationsCluster = {};
    }

    if (!isEmpty(this.CLUSTER_DATA.highlighted)) {
      this.CLUSTER_DATA.highlighted = {};
    }
  }

  // Cluster Work
  renderClusters() {
    const { mapData } = this;
    const { SOURCE_NAME, LAYER_ID, COUNT_LAYER_ID, UNCLUSTERED_POINT_LAYER_ID, UNCLUSTERED_SOURCE } = this.CLUSTER_VARIABLES;

    // ToDo this structure is modified in the slice or reducer, should we return it in this shape from a slice?
    const data = { "type": "FeatureCollection", "features": mapData };

    this.unhighlightActualMarker();
    this.removeClusters();
    this.removeAllMarkers();
    this.cleanAllChloropethLayers();

    this.mapInstance.addSource(SOURCE_NAME, {
      type: "geojson",
      data,
      cluster: true,
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    this.mapInstance.addSource(UNCLUSTERED_SOURCE, {
      type: "geojson",
      data,
      cluster: true,
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      promoteId: 'id', // this is the only way to get a unclustered point in the queries with ID
    });

    this.mapInstance.addLayer({
      id: LAYER_ID,
      type: "circle",
      source: SOURCE_NAME,
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#dc3545', // red
          [
            'step',
            ['get', 'point_count'],
            '#6fa8dc', 100,
            '#3d85c6', 200,
            '#0b5394', 500,
            '#073763',
          ],
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, 100,
            30, 200,
            40, 500,
            50
        ]
      }
    });

    this.mapInstance.addLayer({
        id: COUNT_LAYER_ID,
        type: 'symbol',
        source: SOURCE_NAME,
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 18
        }
    });

    this.mapInstance.addLayer({
      id: UNCLUSTERED_POINT_LAYER_ID,
      type: 'circle',
      source: UNCLUSTERED_SOURCE,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'highlight'], false],
          '#dc3545', // red
          '#3d85c6',
        ],
        'circle-radius': 8,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#0b5394'
      }
    });

    this.mapInstance.once('idle', () => this.mapAllLocationsInClusters()); // once the map finishes rendering
  }

  mapAllLocationsInClusters() {
    const { LAYER_ID, UNCLUSTERED_POINT_LAYER_ID } = this.CLUSTER_VARIABLES;
    let clusters;
    let unclustered;

    try {
      clusters = this.mapInstance.queryRenderedFeatures(null, { 
        layers: [LAYER_ID],
      });

      unclustered = this.mapInstance.queryRenderedFeatures(null, { 
        layers: [UNCLUSTERED_POINT_LAYER_ID],
      });
    } catch (error) {
      console.error(error);
    }

    if(isEmpty(clusters) && isEmpty(unclustered)) {
      console.error('No clusters or points found.');
      return;
    }

    clusters.forEach(this.saveClusterLocationsRelation);
    unclustered.forEach((point) => this.saveClusterLocationsRelation(null, point));
  }

  saveClusterLocationsRelation(cluster, point) {
    const { SOURCE_NAME, UNCLUSTERED_SOURCE } = this.CLUSTER_VARIABLES;
    const figure = cluster || point;
    let isCluster = !isEmpty(cluster);
    let sourceName = isCluster ? SOURCE_NAME : UNCLUSTERED_SOURCE;

    if(isEmpty(figure) || !Boolean(figure.id)) { // escape clusters with no id, they will come in another array with id
      return;
    }

    const mapLocationsCluster = this.CLUSTER_DATA.mapLocationsCluster;

    if(!isCluster) {
      mapLocationsCluster[point.properties.id] = figure;
      return;
    }

    const figureSource = this.mapInstance.getSource(sourceName);

    figureSource.getClusterLeaves(figure.id, figure.pointCount, 0, (error, features) => {
      features.forEach((location) => {
        mapLocationsCluster[location.properties.id] = figure;
      });
    });
  }

  getClusterForLocationId(locationId) {
    return this.CLUSTER_DATA.mapLocationsCluster[locationId] || {};
  }

  isGeoPointItem(geoItem) {
    return geoItem && geoItem.geometry && geoItem.geometry.type === 'Point';
  }

  setClusterHighligthed(cluster) {
    this.CLUSTER_DATA.highlighted = cluster;
    const { id, source } = cluster;
    this.mapInstance.setFeatureState({ id, source, }, { 'highlight': true } );
  }

  findClustersAroundPosition(pointInPx) {
    const { LAYER_ID } = this.CLUSTER_VARIABLES;
    const { x, y } = pointInPx;

    let radio = 20;
    const step = 20;
    const radioLimit = 300;
    let ccluster = {};

    do {
      [
        [x, y],
        [x, y+radio],
        [x+radio, y+radio],
        [x+radio, y],
        [x+radio, y-radio],
        [x,  y-radio],
        [x-radio, y-radio],
        [x-radio, y+radio],
      ]
      .forEach((position) => { // ToDo: change to while
        const search = this.getClusterForPosition(position);
        if(!isEmpty(search)) {
          ccluster = search;
        }
        return ccluster;
      });

      if(!isEmpty(ccluster)) {
        radio = radioLimit;
        return ccluster;
      }
      radio += step;
    } while(radio < radioLimit);

    if (!isEmpty(ccluster)) {
      console.debug('found close cluster:', ccluster);
    }
    return ccluster;
  };

  getClusterForPosition(position) {
    const { LAYER_ID, UNCLUSTERED_POINT_LAYER_ID } = this.CLUSTER_VARIABLES;
    let clusters = [];
    let unclustered = [];

    try {
      clusters = this.mapInstance.queryRenderedFeatures(position, { 
        layers: [LAYER_ID],
      });

      clusters = !isEmpty(clusters) && clusters.filter((cluster) => Boolean(cluster.id)); // remove the non ids clusters

      if(!isEmpty(clusters)) {
        return clusters[0];
      }

      // when no cluster find a unclustered
      let unclustered = this.mapInstance.queryRenderedFeatures(position, { 
        layers: [UNCLUSTERED_POINT_LAYER_ID],
      });
      if (!isEmpty(unclustered)) {
        unclustered = unclustered.filter((point) => Boolean(point.id));
      }
      clusters = unclustered;
    } catch (error) {
      console.error(error);
    }

    return clusters && clusters[0] ? clusters[0] : null;
  }

  findCloseCluster(coordinates) {
    if(isEmpty(coordinates)) {
      return;
    }

    const coordProj = this.mapInstance.project(coordinates);

    // find close cluster
    let ccluster = this.getClusterForPosition(coordProj) || [];

    if(isEmpty(ccluster)) {
      ccluster = this.findClustersAroundPosition(coordProj) || [];
    }

    if(isEmpty(ccluster)) {
      console.debug('No close cluster found.');
    }

    return ccluster;
  }

  highlightClusterContainingLocation(locationId) {
    if (isEmpty(locationId)) {
      return;
    }

    const locationItem = this.mapData.filter((location) => location.properties.id === locationId)[0] || {};
    if(isEmpty(locationItem)) {
      console.error('Location not found:', locationId)
      return;
    }

    const coord = locationItem && locationItem.geometry && locationItem.geometry.coordinates;
    this.removeTempClusterMarker();
    this.silentMoveMapTo(coord);
    setTimeout(() => this.renderTempMarker(locationItem));

    // let cluster = this.getClusterForLocationId(locationId);

    // if (isEmpty(cluster)) {
    //   cluster = this.findCloseCluster(coord);
    // }

    // if (isEmpty(cluster)) {
    //   console.debug('No figure found for location, rendering marker:', locationItem);
      // ToDo: is still necessary?, is not cleaning temp marker on zoom in or out
      // this.silentMoveMapTo(locationItem.geometry.coordinates);
      // this.renderTempMarker(locationItem);
    //   return;
    // }

    // this.unhighlightActualCluster();
    // this.silentMoveMapTo(cluster.geometry.coordinates);
    // setTimeout(() => this.setClusterHighligthed(cluster));
  }

  unhighlightActualCluster() {
    const { highlighted } = this.CLUSTER_DATA;
    if (isEmpty(highlighted)) {
      return;
    }
    this.mapInstance.setFeatureState(highlighted, { 'highlight': false } );
  }

  unhighlightActualMarker() {
    if(isEmpty(this.activeMarker)) {
      return;
    }
    this.closeOpenMarkerPopup();
    this.deactivateMarker();
  }

  unhighlightActualLocation() {
    this.unhighlightActualMarker();

    // This is to remove and close the Temp Marker & Popup
    this.removeTempClusterMarker();

    this.highlightedLocation = {};

    // if(!isEmpty(this.CLUSTER_DATA.highlighted)) {
    //   this.unhighlightActualCluster();
    // }
  }

  highlightLocation(locationId) {
    const { mapType, MAP_TYPES } = this;
    const { CLUSTERS, MARKERS } = MAP_TYPES;

    if (!this.isMapReady) {
      setTimeout(() => this.highlightLocation(locationId), 100);
      return;
    }

    if(!Boolean(locationId)) {
      this.unhighlightActualLocation();
      return;
    }

    if(!isEmpty(this.highlightedLocation)) {
      if (this.highlightedLocation.id === locationId) {
        if (this.highlightedLocation.mapType === this.mapType) {
          return;
        }
        if (this.mapType === MAP_TYPES.MARKERS) {
          this.removeTempClusterMarker();
        }
      }
    }

    this.highlightedLocation = {
      id: locationId,
      mapType: mapType,
    };

    switch(mapType) {
      case MARKERS:
        this.openMarkerPopupById(locationId);
      break;
      case CLUSTERS:
        this.highlightClusterContainingLocation(locationId);
      break;
    }
  }

  highlightClusterWhenMapStopsMoving(cluster) {
    if (this.mapInstance.isMoving()) {
      setTimeout(() => this.highlightClusterWhenMapStopsMoving(cluster), 100);
      return;
    }
    this.setClusterHighligthed(cluster);
  };

  silentMoveMapTo(centerCoordinates) {
    this.ignoreMoveEndOnce = true;
    return this.mapInstance.easeTo({
      center: centerCoordinates,
      speed: .5,
      curve: 1,
      duration: 200,
    });
  }

  // ToDo: clusters in mapbox are unstable, so this is a workaround
  renderTempMarker(location) {
    if (isEmpty(location) && !this.isGeoPointItem(location)) {
      console.error('a valid map geo point with coordinates is required');
      return;
    }

    let removedMarker = false;
    if (!isEmpty(this.tempRenderedMarker)) {
      this.removeTempClusterMarker();
      removedMarker = true;
    }

    if(removedMarker) {
      setTimeout(() => { this.createTempMarker(location); }, 100);
      return;
    }
    this.createTempMarker(location);
  }

  createTempMarker(geoPoint) {
    const { HIGHLIGHT_COLOR: highlightMarkerColor } = this.CLUSTER_VARIABLES;
    const markerNewProperties = Object.assign({}, geoPoint.properties, { 'marker-color': highlightMarkerColor });
    const markerData = Object.assign({}, geoPoint, { properties: markerNewProperties });

    this.removeTempClusterMarker();
    setTimeout(() => { this.tempRenderedMarker = this.createMarker(markerData) });
    setTimeout(() => this.openTempMarkerPopup());
  }

  openTempMarkerPopup() {
    if (isEmpty(this.tempRenderedMarker)) {
      return;
    }

    const { geometry, properties } = this.tempRenderedMarker;
    const lngLat = [geometry.coordinates[0], geometry.coordinates[1]];
    this.tempPopup = this.getPopUp(lngLat, properties);

    // the open and removal of popups are asyncronous so they trigger problems if we execute them without setTimeout
    setTimeout(() => {
      this.tempPopup.addTo(this.mapInstance);  
    }, 100);
  }

  closeTempMarkerPopup() {
    const { tempPopup } = this;
    setTimeout(() => {
      !isEmpty(tempPopup) && tempPopup.remove();
    });
    this.tempPopup = {};
  }

  removeTempClusterMarker() {
    if (isEmpty(this.tempRenderedMarker)) {
      return;
    }

    this.closeTempMarkerPopup();
    this.tempRenderedMarker
      .getElement().removeEventListener('click', this.tempRenderedMarker.listener);
    this.tempRenderedMarker.remove();

    setTimeout(() => {
      this.tempRenderedMarker = {};
    }, 100);
  }
}

export default ResearchMap;