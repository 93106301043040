import React, { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import $ from 'jquery';
import { isEmpty } from 'lodash';

import Logo from './map-tools/Logo';
import ResetMap from './map-tools/ResetButton';
import LocationsSearch from './map-tools/LocationsSearch';
import InfoMenu from './map-tools/InfoMenu';
import SiteInfoModal from './map-tools/SiteInfoModal';
import Sidepanel from './common/Sidepanel';
import LocationsContent from './locations-list/LocationsContent';
import FacetsAccordion from './map-tools/facetsAccordion/FacetsAccordion';
import Ads from './common/Ads';
import { LocationItemShape } from '../shapes/shapes';
import { resetFacets, facetsListSelector } from "../reducers/facetsReducer";
import { fetchLocations } from "../actions/locationActions";

const MapTools = ({
  locationItems,
  activeLocationItem,
  facets,
  pinnedLocationItem,
  isLoadingData,
  hasMarkers,
  adsEnabled,
  adsMaxCount,
  locationsSliderOpen,
  onCloseSidePanel,
}) => {
  const modalId = 'SiteInfoModal';

  const [state, setState] = useState({
    siteInfoModalTab: 0,
    navOpenState: 'closed',
    adCount: 0,
  });

  const { siteInfoModalTab } = state;
  const title = `${locationItems.length} Results`;

  const openModal = (selectedTabIdx) => {
    setState({ ...state, siteInfoModalTab: selectedTabIdx });
    const $modal = $(`#${modalId}`).modal('show');
  };

  const toggleNav = () => {
    const newNavOpenState = (navOpenState === 'closed') ? 'open' : 'closed';
    setState({ ...state, navOpenState: newNavOpenState });
  }

  const getAdCount = (adCount) => {
    setState({ ...state, adCount });
  }

  const { navOpenState, adCount } = state;

  const { selectedFacet, mapConfig, pageData } = useSelector(facetsListSelector);
  const { zoom, mapBounds } = mapConfig;
  const dispatch = useDispatch();

  const handleResetFacets = () => {
    dispatch(resetFacets());
    dispatch(fetchLocations({ pageData, zoom, mapBounds, selectedFacet: {} })); // force fetch locations with not facet filter if the facet is not complete.
  }

  return (<>
    <div className="map-tools">
      <Logo className="top left" />
      <ResetMap />
      <nav className={`main-nav top right ${navOpenState}`}>
        <i className="main-nav__icon bi bi-list" onClick={toggleNav}></i>
        <div className="main-nav__content">
          <InfoMenu onClick={openModal} />
          <LocationsSearch />
          <FacetsAccordion
            handleResetFacets={handleResetFacets}
            isLoadingData={isLoadingData}
            selectedFacet={selectedFacet}
          />
        </div>
      </nav>
      {
        !hasMarkers ?
          <img className="map-legend bottom right" src="/images/legend.png" /> : <></>
      }

      <Sidepanel 
        title={title}
        isOpen={locationsSliderOpen} 
        onClose={onCloseSidePanel}
      >
        <LocationsContent
          locationItems={locationItems}
          activeLocationItem={activeLocationItem}
          showResetFacetsBtn={!isEmpty(facets)}
          pinnedLocationItem={pinnedLocationItem}
          isLoadingData={isLoadingData}
        />
      </Sidepanel>
      {
        adsEnabled === true ?
          (<div className={`ads-container ${!hasMarkers ? 'ads-container__with-map-legend' : ''}`}>
              { adCount > 0 ?
                (<span className="ads-container__sponsored">SPONSORED</span>) : (<></>)
              }
              <div className={`ads-container__list-wrapper ${adCount < adsMaxCount ? `ads-container__list-wrapper--${adCount}` : ''}`}>
                <Ads getAdCount={getAdCount} />
              </div>
          </div>) : (<></>)
      } 
    </div>
    <SiteInfoModal modalId={modalId} actualTab={siteInfoModalTab} />
  </>
  );
}

MapTools.propTypes = {
  locationItems: PropTypes.arrayOf(PropTypes.shape(LocationItemShape)),
  facets: PropTypes.array.isRequired,
  pinnedLocationItem: PropTypes.string,
  isLoadingData: PropTypes.bool.isRequired,
  hasMarkers: PropTypes.bool.isRequired,
  adsEnabled: PropTypes.bool,
  adsMaxCount: PropTypes.number,
};

export default MapTools;