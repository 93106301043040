class KevelService {
  constructor(config) {
    const { adsConfig, kevelClientConfig } = config; 
    this.adsConfig = Object.assign({}, adsConfig);
    this.KevelClient = new AdzerkDecisionSdk.Client(kevelClientConfig);
  }

  static parseAdData(adsData) {
    if (_.isEmpty(adsData)) {
      console.error('empty ads response from kevel service.');
      return;
    }

    return adsData.map(ad => {
      const { adId, clickUrl, impressionUrl, contents } = ad;
      const {
        ctTitle:title,
        ctBody:description,
        externalUrl,
        fileName,
      } = contents[0].data;
      const kevelStaticFilesUrl = 'https://static.adzerk.net/Advertisers/';
      const imageUrl = externalUrl ? externalUrl :  `${kevelStaticFilesUrl}${fileName}`

      return {
        adId,
        clickUrl,
        impressionUrl,
        title,
        description,
        imageUrl,
        type: 'ad'
      };
    });
  }

  getAdds() {
    const { adsConfig } = this;
    return new Promise((resolve, reject) => {
      
      this.KevelClient.decisions.get({ placements: [adsConfig] }).then(response => {
        const { decisions } = response;
        resolve(this.parseAdData(decisions[adsConfig.divName]));
      });
    });
  }
}

export default KevelService;