import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Typeahead from '../common/Typeahead';
import Search from '../../services/search';

const LocationsSearch = ({ className }) => {
  const [items, setItems] = useState([]);
  const maxItems = 6;
  const minChars = 3;

  const onTextChange = (value) => {
    if (!isEmpty(value)) {
      Search(value)
        .then((newItems) => {
          setItems(newItems);
        });
    }
  }

  return (
    <div className={`d-flex locations-search ${className}`}>
      <Typeahead maxItems={maxItems} minChars={minChars} items={items} onTextChange={onTextChange} />
    </div>
  );
}

LocationsSearch.propTypes = {
  className: PropTypes.string
};

export default LocationsSearch;